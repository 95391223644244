<template>
	<label class="selectInput" :disabled="disabled">
		<div class="label">{{ label }}</div>
		<select @input="newValue($event)" :value="value">
			<option
				v-for="item in items"
				:key="`${item[0]}x${item[1]}`"
				:value="item.join('x')"
			>
				{{ item[0] }} x {{ item[1] }}
			</option>
			<option key="custom" value="custom">Egendefinert</option>
		</select>
	</label>
</template>

<script>
export default {
	name: 'SelectInput',
	props: {
		items: {
			type: Array,
			required: true,
		},
		value: {
			type: String,
			required: true,
		},
		label: {
			type: String,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		newValue(event) {
			this.$emit('input', event.target.value)
		},
	},
}
</script>
<style lang="scss" scoped>
.selectInput {
	display: block;
	margin-bottom: 15px;
}
</style>

/* eslint-disable */
export default (videoName, landingPage, posterName) => {
	return `<!DOCTYPE html>
<html lang="no">
	<head>
		<meta charset="UTF-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		<meta http-equiv="X-UA-Compatible" content="ie=edge" />
		<title>Ad</title>
		
		<script>
			const dhtmlScript = document.createElement('script')
			dhtmlScript.addEventListener('load', loaded)
			dhtmlScript.src = window.API_URL || 'https://s1.adform.net/banners/scripts/rmb/Adform.DHTML.js?bv=' + Math.random()
			document.head.append(dhtmlScript)
		
			function loaded() {
			
				const clickTAGvalue = dhtml.getVar('clickTAG', '${landingPage}')
				const landingpagetarget = dhtml.getVar(
					'landingPageTarget',
					'_blank'
				)
			
				document.querySelector('#banner').addEventListener('click', () => {
					window.open(clickTAGvalue, landingpagetarget)
				})
	
				var video = document.getElementById('video')
				video.src = dhtml.getAsset(1)

				setTimeout(() => {
					video.removeAttribute('loop')
					video.currentTime = video.duration
					video.pause()
				}, 30 * 1000)
			}
		</script>


	</head>
	<body>
		<div id="banner">
			<video muted loop autoplay playsinline id="video" ${
				posterName ? `poster="${posterName}"` : ''
			} />
		</div>
		
		<style>
			body {
				margin: 0;
				overflow: hidden;
			}
			#video {
				width: 100%;
				height: 100%;
				object-fit: cover;
				cursor: pointer;
			}
		</style>

	</body>
</html>
<!--#html:common/html--><!--#i1.core.html:core/html--><!--#/#--><!--#/#--><!--#meta:common/meta--><!--/*#i1.core.meta:core/meta*/version: 2;/*#/#*/--><!--#/#-->`
}

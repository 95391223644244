<template>
	<label class="selectInput" :class="[isVideo ? 'is-video' : 'is-image']">
		<div class="label">{{ label }}</div>

		<div class="preview-input-button-holder">
			<div class="preview">
				<img v-if="preview && isImage" :src="preview" alt="" />
				<video
					v-show="preview && isVideo"
					:src="preview"
					controls
					ref="videoPlayer"
				></video>
			</div>
			<div class="input-holder">
				<input
					@change="newValue($event)"
					type="file"
					:accept="accept"
					:size="size"
					ref="fileInput"
				/>
				<p v-if="fileSize" class="file-size">{{ fileSize }}</p>
				<p v-if="preview && isVideo" class="duration">
					({{ duration }} sekunder)
				</p>
			</div>
			<button
				v-if="value"
				@click="removeValue($event)"
				class="remove-value-btn"
			>
				<i class="icon">✕</i><span class="text">Fjern</span>
			</button>
		</div>
	</label>
</template>

<script>
export default {
	name: 'FileInput',
	props: {
		label: {
			type: String,
		},
		value: {},
		accept: {
			default: 'image/*',
		},
		size: {
			default: 10485760,
		},
		duration: {
			default: 0,
		},
	},
	methods: {
		newValue(event) {
			this.$emit('input', event.target.files[0])
		},
		removeValue(event) {
			event.preventDefault()
			this.$refs.fileInput.value = ''
			this.$emit('input', null)
		},
		emitDuration(event) {
			console.log('emitDuration', event)
		},
		formatBytes(bytes, decimals = 2) {
			if (bytes == 0) return '0 Bytes'
			var k = 1024,
				sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
				i = Math.floor(Math.log(bytes) / Math.log(k))
			return (
				parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i]
			)
		},
	},
	computed: {
		isVideo() {
			return this.accept == 'video/*'
		},
		isImage() {
			return this.accept == 'image/*'
		},
		preview() {
			if (!this.value) return ''
			return URL.createObjectURL(this.value)
		},
		fileSize() {
			if (!this.value) return ''
			return this.formatBytes(this.value.size)
		},
	},
	mounted() {
		this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
			this.$emit('setDuration', this.$refs.videoPlayer.duration)
		})
	},
}
</script>
<style lang="scss" scoped>
.selectInput {
	margin-bottom: 20px;
	display: block;
}
.preview-input-button-holder {
	position: relative;
	display: flex;
	gap: 10px;
	border: 1px solid #cdcdcd;
	border-radius: 3px;
	align-items: center;
	@media (max-width: 800px) {
		flex-direction: column;
	}
}
.input-holder {
	flex-grow: 1;
	input {
		width: 100%;
	}
}

.preview {
	width: 200px;
	height: 150px;
	background-color: #f5f5f5;
	border-right: 1px solid #cdcdcd;
	@media (max-width: 800px) {
		flex-direction: column;
		width: 100%;
		border-right: none;
		border-bottom: 1px solid #cdcdcd;
	}
	video,
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
input {
	font-size: 16px;
}
.input-holder p {
	// color: grey;
	margin: 5px 0 0 0;
	font-size: 16px;
}
.remove-value-btn {
	background: white;
	border-radius: 1000px;
	border: 1px solid #cdcdcd;
	padding: 7px 15px;
	margin: 5px;
	font-size: 16px;
}
.icon {
	font-style: normal;
	margin-right: 10px;
}
.is-video .input-holder {
	padding: 10px;
}
</style>

<template>
	<label class="textInput" :disabled="disabled">
		<div class="label">{{ label }}</div>
		<input
			type="text"
			:value="value"
			@input="newValue($event)"
			v-bind="$props"
		/>
	</label>
</template>

<script>
export default {
	name: 'TextInput',
	props: {
		value: {
			required: true,
		},
		label: {},
		disabled: {
			default: false,
		},
	},
	methods: {
		newValue(event) {
			this.$emit('input', event.target.value)
		},
	},
}
</script>
<style lang="scss" scoped>
.textInput {
	display: block;
	margin-bottom: 15px;
}
</style>

<template>
	<div id="app">
		<div class="container">
			<div class="header-download-holder">
				<h1>Videoannonse.zip</h1>
				<button
					@click="downloadZip"
					:disabled="!video || !name || !landingPage"
					class="btn-download"
				>
					<span class="text">Last ned .zip</span
					><img src="@/assets/icon-floppy.svg" class="icon" />
				</button>
			</div>

			<div class="row">
				<div class="col">
					<TextInput label="Navn *" v-model="name" />
				</div>
				<div class="col">
					<TextInput label="Landingsside *" v-model="landingPage" />
				</div>
			</div>
			<div class="row">
				<div class="col">
					<SelectInput :items="sizes" label="Størrelse" v-model="size" />
				</div>
				<div class="col">
					<div class="row">
						<div class="col">
							<TextInput
								label="Egendefinert bredde"
								v-model="customWidth"
								:disabled="size != 'custom'"
							/>
						</div>
						<div class="col">
							<TextInput
								label="Egendefinert høyde"
								v-model="customHeight"
								:disabled="size != 'custom'"
							/>
						</div>
					</div>
				</div>
			</div>
			<FileInput
				label="Video *"
				v-model="video"
				:duration="duration"
				@setDuration="duration = $event"
				accept="video/*"
			/>
			<FileInput label="Ventebilde" v-model="poster" />
		</div>
	</div>
</template>

<script>
// CSS
import '@/assets/global.scss'

// Libraries
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

// Components
import TextInput from '@/components/TextInput'
import SelectInput from '@/components/SelectInput'
import FileInput from '@/components/FileInput'

// Templates
import manifest from '@/assets/manifest.json'
import AdTemplate from '@/components/AdTemplate'
import ADFBannerPropertiesGen from '@/components/ADFBannerProperties'

export default {
	name: 'AdformVideoAd.zip',
	components: {
		TextInput,
		SelectInput,
		FileInput,
	},
	head: {
		title: {
			inner: 'Adform VideoAd',
		},
	},
	data: () => ({
		name: '',
		landingPage: '',
		size: '980x600',
		sizes: [
			['320', '250'],
			['320', '400'],
			['580', '400'],
			['980', '300'],
			['980', '500'],
			['980', '600'],
			['1000', '1000'],
		],
		customWidth: '980',
		customHeight: '600',
		poster: null,
		video: null,
		duration: 0,
	}),
	computed: {
		computedSize() {
			if (this.size == 'custom') {
				return [this.customWidth, this.customHeight]
			}
			return [this.size.split('x')[0], this.size.split('x')[1]]
		},
		randomId: () =>
			[...'longEnoughId']
				.map(() => Math.random().toString(36).substr(2, 9))
				.join(''),
	},
	methods: {
		AdTemplate,
		JSZip,
		saveAs,
		downloadZip() {
			const zip = new JSZip()
			const main = zip.folder('main')

			// manifest.json
			manifest.title = this.name
			manifest.width = this.computedSize[0]
			manifest.height = this.computedSize[1]
			manifest.clicktags.clickTAG = this.landingPage
			manifest.uniqueId = this.randomId
			main.file('manifest.json', JSON.stringify(manifest))

			//  ADFBannerProperties
			zip.file(
				'ADFBannerProperties.xml',
				ADFBannerPropertiesGen(
					this.name,
					this.video.name,
					this.landingPage,
					this.randomId
				)
			)

			// index.html
			main.file(
				'index.html',
				this.AdTemplate(
					this.video.name,
					this.landingPage,
					this.poster ? this.poster.name : null
				)
			)

			// Poster image
			if (this.poster) {
				main.file(this.poster.name, this.poster, { base64: true })
			}

			// Video file
			zip.file(this.video.name, this.video)

			// ZIP it & ship it
			zip.generateAsync({ type: 'blob' }).then(blob => {
				saveAs(
					blob,
					`${this.name} - ${this.computedSize[0]}x${this.computedSize[1]}.zip`
				)
			})
		},
	},
}
</script>

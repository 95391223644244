export default (
	name,
	videoName,
	landingPage,
	id
) => `<?xml version="1.0" encoding="UTF-8"?>
<adf>
  <banner>
    <details>
      <uniqueId>${id}</uniqueId>
      <format>Standard</format>
      <name><![CDATA[${name}]]></name>
      <events>1</events>
    </details>
    <settings>
      <responsiveWidth>0</responsiveWidth>
      <responsiveHeight>0</responsiveHeight>
      <landingPageTarget>_blank</landingPageTarget>
    </settings>
    <assets>
      <asset>
        <sourceType>src</sourceType>
        <fileName>main</fileName>
        <clickTagNames>
          <clickTagName><![CDATA[clickTAG]]></clickTagName>
        </clickTagNames>
        <eventIds/>
      </asset>
      <asset>
        <sourceType>videoSrc</sourceType>
        <fileName><![CDATA[${videoName}]]></fileName>
      </asset>
    </assets>
    <bannerClickTags>
      <bannerClickTag name="clickTAG" displayName="clickTAG"><![CDATA[${landingPage}]]></bannerClickTag>
    </bannerClickTags>
    <bannerEvents/>
    <variables/>
    <video>
      <details/>
      <settings>
        <loop>0</loop>
        <autoPlay>0</autoPlay>
        <soundOn>0</soundOn>
      </settings>
    </video>
  </banner>
</adf>`
